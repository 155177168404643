import { Controller } from '@hotwired/stimulus';

// Connects to data-controller="test-results"
export default class extends Controller {
  static targets = ['state', 'result'];

  connect() {
    this.collapse = new bootstrap.Collapse(this.resultTarget, {
      toggle: this.stateTarget.value == 'resulted',
    });
    this.update();
  }

  disconnect() {
    this.collapse.dispose();
  }

  update() {
    this.resultTarget.querySelectorAll('input, select').forEach((input) => {
      input.toggleAttribute('disabled', this.stateTarget.value !== 'resulted');
    });
    if (this.stateTarget.value == 'resulted') {
      this.collapse.show();
    } else {
      this.collapse.hide();
    }
  }
}
